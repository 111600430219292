import { Navigate, Route, Routes } from 'react-router';
import { ROUTES } from './constants/routes';
import { Auth0Guard } from './Auth0Guard';
import { PortCoPortalLayout } from './pages/PortCoPortalLayout/PortCoPortalLayout';
import { PendingRequests } from './pages/PendingRequests';
import { ApprovedRequests } from './pages/ApprovedRequests';
import { Questionnaire } from './pages/Questionnaire/Questionnaire';
import { QuestionnaireContent } from './pages/Questionnaire/components/QuestionnaireContent';
import { Company } from './pages/Company/Company';
import { TabsContent } from './pages/Company/TabsContent';
import { useInitUser } from './hooks/useInitUser';
import { useAtomValue } from 'jotai';
import { userState } from './state/UIState';
import { LOCAL_USER_STATE } from './constants/constants';
import { Unauthorized } from './pages/Unauthorized/Unauthorized';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from './types';

function App() {
  useInitUser();
  const user = useAtomValue(userState);
  const { isAuthenticated } = useAuth0();
  if (user === LOCAL_USER_STATE.LOADING && isAuthenticated) return null;
  if ((user === LOCAL_USER_STATE.NO_USER_FOUND || !(user as User)?.company) && isAuthenticated)
    return <Unauthorized />;

  return (
    <Routes>
      <Route path='/' element={<Auth0Guard component={PortCoPortalLayout} />}>
        <Route index element={<Navigate replace to={ROUTES.PENDING_REQUESTS} />} />
        <Route path={ROUTES.PENDING_REQUESTS} element={<PendingRequests />} />
        <Route path={ROUTES.APPROVED_REQUESTS} element={<ApprovedRequests />} />
        <Route path={`${ROUTES.QUESTIONNAIRES}/:reportId`} element={<Questionnaire />}>
          <Route path={`:activeStep`} element={<QuestionnaireContent isPortal />} />
        </Route>
        <Route path={`${ROUTES.COMPANIES}/:id`} element={<Company />}>
          <Route path={`:activeTab`} element={<TabsContent />} />
        </Route>
      </Route>
      <Route path='*' element={<Auth0Guard component={PortCoPortalLayout} />} />
    </Routes>
  );
}

export default App;
