import { Button, IconButton, Modal as MuiModal, styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-gray.svg';
import { CSSProperties, useMemo } from 'react';
import { InfoContent } from './InfoContent';

const ModalWrapper = styled('div')``;

const Wrapper = styled('div')`
  width: 564px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 4px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled('div')`
  padding: 16px 32px 32px 32px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
`;

const ButtonsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 16px 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary[30]};
`;

interface Props {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  infoText?: string;
  children?: React.ReactNode;
  onConfirm: () => void;
  cancelBtnText?: string;
  confirmBtnText?: string;
  confirmBtnColor?: string;
  isConfirmDisabled?: boolean;
  contentContainerStyle?: CSSProperties;
  infoContent?: React.ReactNode;
  loadingSkeleton?: React.ReactNode;
  isLoading?: boolean;
  hideCancel?: boolean;
  isInfoContentWarning?: boolean;
}

export const Modal = ({
  isOpen,
  children,
  title,
  onClose,
  infoText,
  cancelBtnText,
  confirmBtnText,
  confirmBtnColor,
  isConfirmDisabled,
  onConfirm,
  contentContainerStyle,
  infoContent,
  isLoading,
  loadingSkeleton,
  hideCancel,
  isInfoContentWarning,
}: Props) => {
  const { colors } = useTheme();

  const confirmButtonColor = useMemo(() => {
    if (isConfirmDisabled) return colors.primary[30];

    return confirmBtnColor || colors.accent[50];
  }, [colors.accent, colors.primary, confirmBtnColor, isConfirmDisabled]);

  const closeModalOnEscape = (e: Event, reason: 'backdropClick' | 'escapeKeyDown') => {
    e.stopPropagation();
    if (reason === 'escapeKeyDown') {
      onClose();
    }
  };

  return (
    <MuiModal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={closeModalOnEscape}
    >
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <Wrapper>
          {isLoading ? (
            loadingSkeleton || <></>
          ) : (
            <>
              <Content style={contentContainerStyle || {}}>
                <TitleWrapper>
                  <Typography variant='h5' color={colors.primary[90]}>
                    {title}
                  </Typography>
                  {!hideCancel && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                      }}
                      style={{ marginRight: '-10px' }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </TitleWrapper>
                <InfoContent
                  infoText={infoText}
                  infoContent={infoContent}
                  isInfoContentWarning={isInfoContentWarning}
                />
                {children}
              </Content>
              <ButtonsWrapper>
                {!hideCancel && (
                  <Button
                    variant='outlined'
                    onClick={(e) => {
                      e.stopPropagation();
                      onClose();
                    }}
                  >
                    {cancelBtnText || 'Cancel'}
                  </Button>
                )}
                <Button
                  variant='contained'
                  onClick={onConfirm}
                  disabled={isConfirmDisabled}
                  style={{ backgroundColor: confirmButtonColor }}
                >
                  {confirmBtnText || 'Save Changes'}
                </Button>
              </ButtonsWrapper>
            </>
          )}
        </Wrapper>
      </ModalWrapper>
    </MuiModal>
  );
};
