import { styled, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { Typography } from '../../components/Typography/Typography';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Header = styled('div')`
  padding: 16px 40px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
`;

const ContentWrapper = styled('div')`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
`;

interface Props {
  title: string;
  children: ReactNode;
}

export const BasicLayout = ({ title, children }: Props) => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Header>
        <Typography variant='h3' color={colors.primary[90]}>
          {title}
        </Typography>
      </Header>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};
