import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left-blue.svg';
import { useNavigate } from 'react-router';

const BreadcrumbsSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BreadcrumbsWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const BreadcrumbItem = styled('div')`
  display: flex;
  align-items: center;
`;

type PrevRoute = {
  prevRoute: string | -1;
  prevRouteName: string;
};

interface Props {
  prevRoutes: PrevRoute[];
  currentRouteName: string;
}

export const Breadcrumbs = ({ prevRoutes, currentRouteName }: Props) => {
  const { colors } = useTheme();

  const navigate = useNavigate();

  return (
    <BreadcrumbsSection>
      <BreadcrumbsWrapper>
        {prevRoutes?.map((prev, i) => {
          return (
            <BreadcrumbItem key={prev.prevRouteName}>
              <Button
                onClick={() => navigate(prev.prevRoute as string)}
                variant='text'
                startIcon={i === 0 && <ArrowLeftIcon />}
                style={{ minWidth: 'auto' }}
              >
                <Typography variant='subtitle1' color={colors.accent[50]}>
                  {prev.prevRouteName}
                </Typography>
              </Button>
              {i !== prevRoutes?.length && (
                <Typography variant='subtitle1' color={colors.primary[90]}>
                  /
                </Typography>
              )}
            </BreadcrumbItem>
          );
        })}
        <Typography variant='subtitle1' color={colors.primary[90]} style={{ marginLeft: '6px' }}>
          {currentRouteName}
        </Typography>
      </BreadcrumbsWrapper>
    </BreadcrumbsSection>
  );
};
