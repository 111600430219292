import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import { CoreKpiCategory, LoadingId } from '../types';

export const CORE_KPI_CATEGORIES = 'coreKpiCategories';

async function fetchCoreKpiCategories(): Promise<CoreKpiCategory[]> {
  const { data } = await Http.axios.get<CoreKpiCategory[]>(`/core-kpi-category-portal`);
  return data.map((category) => ({
    ...category,
    kpis: category.kpis.sort((a, b) => a.order - b.order),
  }));
}

export function useCoreKpiCategories() {
  return useQuery(CORE_KPI_CATEGORIES, () => fetchCoreKpiCategories(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function createCoreKpiCategory(payload: Partial<CoreKpiCategory>): Promise<CoreKpiCategory> {
  const { data } = await Http.axios.post<Partial<CoreKpiCategory>, CoreKpiCategory>(
    `/core-kpi-category`,
    payload
  );
  return data;
}

export function useCreateCoreKpiCategory() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation(createCoreKpiCategory, {
    onMutate: async () => {
      startLoading(LoadingId.createCoreKpiCategory);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create core KPI category' });
    },
    onSuccess: (newCoreKpiCategory) => {
      queryClient.setQueryData(
        CORE_KPI_CATEGORIES,
        (oldCategories: CoreKpiCategory[] | undefined) =>
          oldCategories ? [...oldCategories, newCoreKpiCategory] : [newCoreKpiCategory]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(CORE_KPI_CATEGORIES);
      stopLoading(LoadingId.createCoreKpiCategory);
    },
  });
}

async function updateCoreKpiCategory(
  coreKpiCategory: Partial<CoreKpiCategory>
): Promise<CoreKpiCategory> {
  const { id, ...payload } = coreKpiCategory;
  const { data } = await Http.axios.patch<Partial<CoreKpiCategory>, CoreKpiCategory>(
    `/core-kpi-category/${id}`,
    payload
  );
  return data;
}

export function useUpdateCoreKpiCategory() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateCoreKpiCategory, {
    onMutate: async () => {
      startLoading(LoadingId.updateCoreKpiCategory);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update core KPI Category' });
    },
    onSuccess: (updatedCoreKpiCategory) => {
      queryClient.setQueryData(
        CORE_KPI_CATEGORIES,
        (oldCategories: CoreKpiCategory[] | undefined) =>
          oldCategories
            ? [
                ...oldCategories.map((category) =>
                  category.id === updatedCoreKpiCategory.id ? updatedCoreKpiCategory : category
                ),
              ]
            : [updatedCoreKpiCategory]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(CORE_KPI_CATEGORIES);
      stopLoading(LoadingId.updateCoreKpiCategory);
    },
  });
}
