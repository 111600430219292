import { styled, useTheme } from '@mui/material';
import { QuestionOption, SurveyQuestion } from '../../../../../types';
import { Radio } from '../../../../../components/Radio/Radio';
import { Typography } from '../../../../../components/Typography/Typography';
import { useCallback, useState } from 'react';
import { NotesBoxPopup } from '../../../../../components/NotesBoxPopup/NotesBoxPopup';
import { AddNoteWrapper, OptionWrapper } from '../common';
import { ConfirmDeleteModal } from '../../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { useAtom } from 'jotai';
import {
  activeQuestionIdState,
  activeSectionIdState,
  responsesState,
} from '../../../../../state/UIState';
import { AddNote } from '../../../../../components/AddNote/AddNote';
import { useIsQuestionDisabled } from '../useIsQuestionDisabled';

const OptionTextWrapper = styled('div')`
  position: relative;
  display: flex;
`;

interface Props {
  option: QuestionOption;
  isChecked: boolean;
  optionResponse: QuestionOption;
  question: SurveyQuestion;
}

export const RadioOption = ({ question, option, isChecked, optionResponse }: Props) => {
  const { colors } = useTheme();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const isQuestionDisabled = useIsQuestionDisabled();

  const [responses, setResponses] = useAtom(responsesState);
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);
  const [newOption, setNewOption] = useState<QuestionOption | null>(null);

  const onChangeOption = useCallback(
    (option: QuestionOption) => {
      setResponses((oldResponses) => {
        return {
          ...oldResponses,
          [question.id]: {
            ...option,
            notes: '',
          },
        };
      });
    },
    [question.id, setResponses]
  );

  const onRadioClick = useCallback(
    (option: QuestionOption) => {
      if (isQuestionDisabled) return;
      const isTheSameOption = (responses[question.id] as QuestionOption)?.id === option.id;
      if (
        responses[question.id] &&
        !isTheSameOption &&
        (responses[question.id] as QuestionOption)?.notes
      ) {
        setNewOption(option);
        return;
      }

      if (isTheSameOption && (responses[question.id] as QuestionOption)?.notes) {
        setNewOption(null);
        return;
      }

      onChangeOption(option);
    },
    [isQuestionDisabled, onChangeOption, question.id, responses]
  );

  const onChangeNotes = useCallback(
    (notes: string) => {
      setResponses((oldResponses) => {
        return {
          ...oldResponses,
          [question.id]: {
            ...(oldResponses[question.id] as QuestionOption),
            notes: notes,
          },
        };
      });
    },
    [question, setResponses]
  );

  const onDeleteNotes = useCallback(() => {
    setResponses((oldResponses) => {
      return {
        ...oldResponses,
        [question.id]: {
          ...(oldResponses[question.id] as QuestionOption),
          notes: '',
        },
      };
    });
  }, [question.id, setResponses]);

  const onFocus = useCallback(() => {
    setActiveQuestionId(question.id);
    setActiveSectionId(question.sectionId);
  }, [question.id, question.sectionId, setActiveQuestionId, setActiveSectionId]);

  return (
    <OptionWrapper
      disabled={isQuestionDisabled}
      key={option.id}
      onClick={() => onRadioClick(option)}
    >
      <Radio
        checked={isChecked}
        inputProps={{ onFocus: onFocus }}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 18,
          },
        }}
        disabled={isQuestionDisabled}
      />
      <OptionTextWrapper>
        <NotesBoxPopup
          notes={optionResponse?.notes || ''}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          onChangeNotes={onChangeNotes}
          withActionButtons
          popupPlacement='right-start'
          popupOffset={{ mainAxis: 8, crossAxis: -8 }}
          isDisabled={isQuestionDisabled}
        >
          <Typography
            variant='h5'
            color={colors.primary[70]}
            style={{
              lineHeight: 1.2,
              background: optionResponse?.notes ? colors.warning[20] : 'transparent',
            }}
          >
            {option.name}
          </Typography>
        </NotesBoxPopup>
        {isChecked && !optionResponse?.notes && !isQuestionDisabled && (
          <AddNoteWrapper>
            <AddNote onNotesChange={onChangeNotes} popupPlacement='bottom-end' />
          </AddNoteWrapper>
        )}

        {isDeleteModalOpen && (
          <ConfirmDeleteModal
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            onConfirm={() => {
              onDeleteNotes();
              setIsDeleteModalOpen(false);
            }}
            title='Delete this note?'
            note='This action cannot be undone.'
            confirmLabel='Yes, Delete'
            cancelLabel='No, Cancel'
            isOpen={isDeleteModalOpen}
          />
        )}
        {newOption && (
          <ConfirmDeleteModal
            onClose={() => {
              setNewOption(null);
            }}
            onConfirm={() => {
              onChangeOption(newOption);
              setNewOption(null);
            }}
            title='Making this change will erase the note. Are you sure?'
            note='This action cannot be undone.'
            confirmLabel='Yes, Change'
            cancelLabel='No, Cancel'
            isOpen={Boolean(newOption)}
          />
        )}
      </OptionTextWrapper>
    </OptionWrapper>
  );
};
