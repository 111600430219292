import { styled, useTheme } from '@mui/material';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock-blue.svg';
import { useMemo } from 'react';
import { Typography } from '../../../components/Typography/Typography';

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
  min-width: 80px;
`;

const IconWrapper = styled('div')<{ color: string }>`
  display: flex;
  align-items: center;
  & path {
    fill: ${({ color }) => color};
  }
`;

interface Props {
  deadlineDateStr: string;
}

export const DeadlineDaysIndicator = ({ deadlineDateStr }: Props) => {
  const { colors } = useTheme();

  const now = new Date();
  const deadlineDate = new Date(deadlineDateStr);

  const diff = deadlineDate.getTime() - now.getTime();

  const diffInDays = Math.round(diff / (1000 * 3600 * 24));

  const iconColor = useMemo(() => {
    if (diffInDays <= 10) return colors.warning[20];

    if (diffInDays <= 0) return colors.error[20];

    return colors.success[30];
  }, [colors.error, colors.success, colors.warning, diffInDays]);

  const textColor = useMemo(() => {
    if (diffInDays <= 10) return colors.warning[50];

    if (diffInDays <= 0) return colors.error[50];

    return colors.success[50];
  }, [colors.error, colors.success, colors.warning, diffInDays]);

  const text = useMemo(() => {
    if (diffInDays < 0) return `Late (${Math.abs(diffInDays)} days)`;

    return `${Math.abs(diffInDays)} days`;
  }, [diffInDays]);

  if (!deadlineDateStr) return null;

  return (
    <Container>
      <IconWrapper color={iconColor}>
        <ClockIcon />
      </IconWrapper>
      <Typography variant='caption' color={textColor}>
        {text}
      </Typography>
    </Container>
  );
};
