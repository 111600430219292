/* istanbul ignore file */
import { ThemeOptions, createTheme } from '@mui/material';
import { colors } from './colors';
import { text } from './text';

export const starcierTheme: ThemeOptions = createTheme({
  text,
  typography: {
    fontFamily: 'PPNeueMontreal-Regular',
    fontWeightBold: 'PPNeueMontreal-Bold',
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-thumb': {
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 0px 1px 2px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
          },
          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: colors.accent[50],
          },
          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: colors.accent[50],
          },
          '& .MuiSwitch-track': {
            backgroundColor: colors.primary[30],
            opacity: 1,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontFamily: text.font.fontFamilyMedium,
          fontWeight: text.weight.medium,
          fontSize: text.size.mediumSmall,
          lineHeight: text.lineHeight.small,
          height: '36px',
          textTransform: 'none',
          '&.MuiButton-outlined': {
            color: colors.accent[50],
            border: `1px solid ${colors.accent[20]}`,
            backgroundColor: colors.accent[5],
          },
          '&.MuiButton-text': {
            height: '30px',
          },
          '&.MuiButton-text:hover': {
            height: '30px',
            backgroundColor: 'transparent',
          },
          '&.MuiButton-outlined:hover': {
            border: `1px solid ${colors.accent[30]}`,
            backgroundColor: colors.accent[10],
          },
          '&.MuiButton-contained': {
            color: colors.primary[0],
            backgroundColor: colors.accent[50],
          },
          '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: colors.primary[30],
          },
          '&.MuiButton-contained:hover': {
            backgroundColor: colors.accent[60],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: text.font.fontFamilyRegular,
          fontWeight: text.weight.regular,
          fontSize: text.size.small,
          lineHeight: text.lineHeight.small,
          padding: '12px 12px',
          minHeight: '0',
          color: colors.primary[70],
          '&&.mui-custom-tab.Mui-selected': {
            color: colors.accent[50],
            fontFamily: text.font.fontFamilyMedium,
            fontWeight: text.weight.medium,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTabs-indicator': {
            backgroundColor: colors.accent[50],
            minHeight: '1px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.primary[40],
          '&.Mui-checked': {
            color: colors.accent[50],
          },
          '&.MuiCheckbox-indeterminate': {
            color: colors.accent[50],
          },
          // style disabled checkbox
          '&.Mui-disabled': {
            color: colors.accent[20],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.accent[50]}`,
          },
          '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.accent[50]}`,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary[30],
          },
          '.MuiInputBase-root': {
            height: '32px',
          },
          '.MuiInputBase-root .MuiInputBase-input': {
            fontFamily: text.font.fontFamilyRegular,
            fontWeight: text.weight.regular,
            fontSize: text.size.mediumSmall,
            lineHeight: text.lineHeight.mediumSmall,
            color: colors.primary[90],
          },
          '.MuiInputBase-root .MuiInputBase-input::placeholder': {
            color: colors.primary[70],
            opacity: 1,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '284px',
          padding: '12px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          height: '32px',
          borderRadius: '4px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '0 8px',
          height: '26px',
          backgroundColor: colors.primary[20],
          color: colors.primary[90],
          borderRadius: '4px',

          justifyContent: 'start',
          '.MuiChip-label': {
            padding: '0',
            fontSize: text.size.small,
            lineHeight: text.lineHeight.small,
            fontWeight: text.weight.regular,
            letterSpacing: '0px',
          },
          '.MuiChip-icon': {
            margin: '0 4px 0 0',
          },
        },
      },
    },
  },
  colors,
});
