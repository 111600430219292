import { useAuth0 } from '@auth0/auth0-react';
import { Http } from '../services/Http';
import { useEffect } from 'react';
import { fetchUserByEmail } from '../queries/useUsers';
import { useSetAtom } from 'jotai';
import { userState } from '../state/UIState';
import { LOCAL_USER_STATE } from '../constants/constants';

export function useInitUser() {
  const { getAccessTokenSilently, user: authOuser } = useAuth0();
  const accessToken = getAccessTokenSilently();
  const setUserLocalState = useSetAtom(userState);
  Http.init(accessToken);

  useEffect(() => {
    const initUser = async () => {
      if (!authOuser || !Http.axios) return;
      const user = await fetchUserByEmail(authOuser?.email || '');
      setUserLocalState(user || LOCAL_USER_STATE.NO_USER_FOUND);
    };
    initUser();
  }, [authOuser, setUserLocalState]);
}
