import { MenuItemBase } from './components/MenuItemBase';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up.svg';
import { MenuItemLabel } from './components/MenuItemLabel';
import { useMemo, useState } from 'react';
import { useActiveRoute } from './useActiveRoute';
import { ButtonBase, styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { TMenuItem } from '../../../types';
import { Typography } from '../../Typography/Typography';

const DropdownWrapper = styled('div')``;

const DropdownItemsWrapper = styled('div')`
  padding-left: 50px;
`;

const SubItem = styled(ButtonBase)`
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Badge = styled('div')`
  background-color: ${({ theme }) => theme.colors.accent[50]};
  border-radius: 100px;
  padding: 0px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 20px;
`;

interface Props {
  item: TMenuItem;
  isExpanded: boolean;
  rootPath?: string;
}

export const MenuItemDropdown = ({ item, isExpanded, rootPath = '' }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const { colors, text } = useTheme();

  const navigate = useNavigate();

  const onToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const onClick = (route?: string) => {
    if (!route) return;
    navigate(`/${route}`);
  };

  const activeRoute = useActiveRoute(rootPath);

  const isDropdownActive = useMemo(() => {
    const subitemRoutes = item?.subItems?.map((subitem) => subitem.id) || [];

    return subitemRoutes?.includes(activeRoute);
  }, [activeRoute, item?.subItems]);

  return (
    <DropdownWrapper>
      <MenuItemBase
        onClick={onToggleOpen}
        labelContent={
          <MenuItemLabel
            title={item.title}
            icon={isOpen ? <ArrowDown /> : <ArrowUp />}
            isActive={isDropdownActive}
          />
        }
        icon={isDropdownActive ? item.iconActive : item.icon}
        isExpanded={isExpanded}
      />
      {isOpen && isExpanded && (
        <DropdownItemsWrapper>
          {item?.subItems?.map((subitem) => {
            const badgeNum = (subitem?.badgeNum || 0) > 999 ? '999+' : subitem?.badgeNum;

            return (
              <SubItem
                key={subitem.id}
                onClick={() => onClick(subitem.route)}
                style={{ paddingRight: badgeNum ? '10px' : 0 }}
              >
                <MenuItemLabel title={subitem.title} isActive={activeRoute === subitem.route} />
                {Boolean(badgeNum) && (
                  <Badge>
                    <Typography
                      variant='tabLabel'
                      color={colors.primary[0]}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 700,
                        fontFamily: text.font.fontInconsolataSemiBold,
                      }}
                    >
                      {badgeNum}
                    </Typography>
                  </Badge>
                )}
              </SubItem>
            );
          })}
        </DropdownItemsWrapper>
      )}
    </DropdownWrapper>
  );
};
