import { useMemo } from 'react';
import { useReportsByCompanyId } from '../queries/useReports';
import { SURVEY_STAGE, SURVEY_TYPE, User } from '../types';
import { useAtomValue } from 'jotai';
import { userState } from '../state/UIState';

export const usePortCoPortalReports = () => {
  const user: User = useAtomValue(userState) as User;
  const { data: reports } = useReportsByCompanyId(user?.company?.id);

  const data = useMemo(() => {
    return reports?.filter(
      (report) =>
        (!report?.survey.stage ||
          report?.survey?.stage === SURVEY_STAGE.CHECKLIST_ANNUALLY ||
          report?.survey?.stage === SURVEY_STAGE.CHECKLIST_QUARTERLY ||
          report?.survey?.stage === SURVEY_STAGE.CORE_KPI) &&
        report?.survey?.type !== SURVEY_TYPE.SYSTEM
    );
  }, [reports]);

  return { data };
};
