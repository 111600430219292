import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { AppConfig, LoadingId } from '../types';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';

export const APP_CONFIG = 'app_config';

async function fetchAppConfig(): Promise<AppConfig> {
  const { data } = await Http.axios.get<AppConfig>(`/config-portal`);
  return data;
}

export function useAppConfig() {
  return useQuery(APP_CONFIG, () => fetchAppConfig(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function updateAppConfig(appConfig: Partial<AppConfig>): Promise<AppConfig> {
  const { data } = await Http.axios.patch<Partial<AppConfig>, AppConfig>(`/config/`, appConfig);
  return data;
}

export function useUpdateAppConfig() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateAppConfig, {
    onMutate: async () => {
      startLoading(LoadingId.updateAppConfig);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update app config' });
    },
    onSettled: () => {
      queryClient.refetchQueries(APP_CONFIG);
      stopLoading(LoadingId.updateAppConfig);
    },
  });
}
