import { styled } from '@mui/material';

import { useParams } from 'react-router';

import { CompletedStep } from './CompletedStep';
import { ACTION_BAR_HEIGHT, HEADER_HEIGHT } from '../../../constants/layoutSizes';

import { FooterButtons } from './FooterButtons';
import { QuestionnaireStep } from '../../SurveyEditor/components/QuestionnaireStep/QuestionnaireStep';

const Wrapper = styled('div')<{ withFooter?: boolean }>`
  height: ${({ withFooter }) =>
    withFooter
      ? `calc(100vh - ${HEADER_HEIGHT}px - ${ACTION_BAR_HEIGHT}px)`
      : `calc(100vh - ${HEADER_HEIGHT}px)`};
`;

export const QuestionnaireContent = ({
  isInternal = false,
  isPortal = false,
}: {
  isInternal?: boolean;
  isPortal?: boolean;
}) => {
  const params = useParams();

  const activeStep = params.activeStep;

  return (
    <Wrapper withFooter={activeStep === 'progress'}>
      {activeStep === 'progress' && (
        <QuestionnaireStep isInternal={isInternal} isPortal={isPortal} withFooter />
      )}
      {activeStep === 'done' && <CompletedStep />}
      {activeStep === 'progress' && <FooterButtons />}
    </Wrapper>
  );
};
