import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useActiveRoute = (rootPath?: string) => {
  const location = useLocation();

  const activeRoute = useMemo(() => {
    return location.pathname.split('/')?.filter((path) => path !== rootPath && path !== '')?.[0];
  }, [location.pathname, rootPath]);

  return activeRoute;
};
