import { styled, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import { PORTAL_HEADER_HEIGHT } from '../../constants/layoutSizes';
import { useCompanyById } from '../../queries/useCompanies';
import { Typography } from '../../components/Typography/Typography';

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 40px;
  height: ${PORTAL_HEADER_HEIGHT}px;
  margin-bottom: 20px;
`;

const Img = styled('img')`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
`;

export const CompanyHeader = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  const { colors } = useTheme();

  return (
    <TitleWrapper>
      {company?.logoUrl && <Img src={company.logoUrl} />}
      <Typography variant='h1' color={colors.primary[90]}>
        {company?.name}
      </Typography>
    </TitleWrapper>
  );
};
