import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { LoadingId, User, UserPayload } from '../types';
import { AxiosError } from 'axios';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { SERVER_ERRORS } from '../constants/serverErrors';

export const USERS = 'users';
export const ROLES = 'roles';

export async function fetchUserByEmail(email: string): Promise<User | null> {
  try {
    const response = await Http.axios.get<User>(`external-user/email/${email}`);
    return response?.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) return null;
    throw error;
  }
}

export function useUserById(email: string) {
  return useQuery(`${USERS}-${email}`, () => fetchUserByEmail(email), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function updateUser(user: Partial<UserPayload>): Promise<User | { message: string }> {
  const { id, ...payload } = user;
  const { data } = await Http.axios.patch<Partial<UserPayload>, User>(
    `/external-user/${id}`,
    payload
  );
  return data;
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast, pushSuccessToast } = useToastMessage();

  return useMutation(updateUser, {
    onMutate: async () => {
      startLoading(LoadingId.updateUser);
    },
    onError: () => {
      pushErrorToast({ message: 'Failed to update user' });
    },
    onSuccess: (response) => {
      if ((response as { message: string })?.message === SERVER_ERRORS.THERE_MUST_BE_ONE_ADMIN) {
        pushErrorToast({ message: SERVER_ERRORS.THERE_MUST_BE_ONE_ADMIN });
        return;
      }
      pushSuccessToast({ message: 'User updated successfully' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(USERS);
      stopLoading(LoadingId.updateUser);
    },
  });
}
