import { FC, LegacyRef, ReactNode } from 'react';
import { styled } from '@mui/material';
import { useIntersection2 } from '../../hooks/useIntersection';

const Td = styled('td')<{ width?: string; minWidth?: string; maxWidth?: string }>`
  width: ${({ width }) => (width ? width : 'auto')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  text-align: left;
  padding: 0 16px 0 0;
  &:first-of-type {
    padding-left: 16px;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface IProps {
  children: ReactNode;
  colSpan?: number;
  style?: React.CSSProperties;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  disableLazyLoading?: boolean;
}

export const TableData: FC<IProps> = ({
  colSpan,
  style,
  width,
  minWidth,
  maxWidth,
  disableLazyLoading = false,
  children,
}) => {
  const [isIntersecting, ref] = useIntersection2();
  
  return (
    <Td
      style={style}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      colSpan={colSpan}
      ref={ref as LegacyRef<HTMLTableDataCellElement>}
    >
      {(isIntersecting || disableLazyLoading) && children}
    </Td>
  );
};
