import { Button, Fade, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload-blue.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar-blue.svg';
import {
  ColumnFiltersState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  CORE_KPI_FORMAT,
  CompanyKpisData,
  CoreKpiRequestFrequency,
  KpisMatrixData,
  SURVEY_FREQUENCY,
  SelectItem,
} from '../../../../types';
import { useCallback, useMemo, useState } from 'react';
import { useCoreKpiCategories } from '../../../../queries/useCoreKpiCategories';
import { Table } from '../../../../components/Table/Table';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { ChipsSelectorV2 } from '../../../../components/ButtonSelector/ChipsSelectorV2';
import { Multiselect } from '../../../../components/Multiselect/Multiselect';
import { useKpiMatrix } from '../../hooks/useKpiMatrix';
import { useAtom } from 'jotai';
import {
  activeKpiMatrixTableCellState,
  companyKpiRequestFrequencyState,
  companyKpisDataState,
} from '../../../../state/UIState';
import { KpisMatrixTimeframeModal } from '../KpisMatrixTimeframeModal';
import { ROUTES } from '../../../../constants/routes';
import { useNavigate, useParams } from 'react-router';
import { KpiPeriodValueCell } from './KpiPeriodValueCell';
import { KPI_MATRIX_TABLE_ID } from '../../../../constants/constants';
import { useAppConfig } from '../../../../queries/useAppConfig';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';

const Wrapper = styled('div')`
  padding: 20px 40px;
`;
const ControlsWrapper = styled('div')`
  margin: 20px 0 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftControlGroup = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const RightControlGroup = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const ButtonGroup = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const FrequencySelector = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const HeaderCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 30px;
`;

const columnHelper = createColumnHelper<KpisMatrixData>();

export const KpisMatrix = () => {
  const navigate = useNavigate();
  const { id: companyId } = useParams<{ id: string }>();
  const { colors } = useTheme();
  const [isTimeframeModalOpen, setIsTimeframeModalOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedCategories, setSelectedCategories] = useState<SelectItem[]>([]);
  const [selectedMetrics, setSelectedMetrics] = useState<SelectItem[]>([]);
  const { onUpdateMatrixCell, getTableData } = useKpiMatrix();
  const { data: coreKpiCategories } = useCoreKpiCategories();
  const [companyKpiRequestFrequency] = useAtom(companyKpiRequestFrequencyState);
  const [selectedFrequencyPreview, setSelectedFrequencyPreview] = useState<CoreKpiRequestFrequency>(
    companyKpiRequestFrequency as CoreKpiRequestFrequency
  );

  const [companyKpisData] = useAtom(companyKpisDataState);

  const { data: appConfig } = useAppConfig();

  const data = useMemo(() => {
    if (!companyKpisData || !coreKpiCategories || !appConfig?.data?.coreKpiCategoriesOrder?.length)
      return [];
    return getTableData(
      selectedFrequencyPreview,
      companyKpisData,
      coreKpiCategories,
      appConfig?.data?.coreKpiCategoriesOrder
    );
  }, [
    appConfig?.data?.coreKpiCategoriesOrder,
    companyKpisData,
    coreKpiCategories,
    getTableData,
    selectedFrequencyPreview,
  ]);

  const [activeCell, setActiveCell] = useAtom(activeKpiMatrixTableCellState);

  const frequencyPreviewOptions: SelectItem[] = useMemo(() => {
    if (!companyKpiRequestFrequency) return [];
    const annuallyOption: SelectItem = {
      id: SURVEY_FREQUENCY.ANNUALLY,
      value: 'Annually',
    };
    const quarterlyOption: SelectItem = {
      id: SURVEY_FREQUENCY.QUARTERLY,
      value: 'Quarterly',
    };
    const monthlyOption: SelectItem = {
      id: SURVEY_FREQUENCY.MONTHLY,
      value: 'Monthly',
    };
    if (companyKpiRequestFrequency === SURVEY_FREQUENCY.QUARTERLY) {
      return [quarterlyOption, annuallyOption];
    }
    if (companyKpiRequestFrequency === SURVEY_FREQUENCY.MONTHLY) {
      return [monthlyOption, quarterlyOption, annuallyOption];
    }
    return [];
  }, [companyKpiRequestFrequency]);

  const onDataCellChange = useCallback(
    async (value: string, kpiId: number, period: string, format: CORE_KPI_FORMAT) => {
      await onUpdateMatrixCell({
        newValue: format === CORE_KPI_FORMAT.TEXT ? value : Number(value),
        kpiId: kpiId as number,
        period,
        activeFrequency: selectedFrequencyPreview,
        format,
      });
    },
    [onUpdateMatrixCell, selectedFrequencyPreview]
  );

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            KPI
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 0,
            backgroundColor: colors.primary[0],
            zIndex: 1,
          },
          thStyles: {
            position: 'sticky',
            left: 0,
            zIndex: 1,
          },
        },
        enableSorting: true,
      }),
      columnHelper.accessor('categoryName', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Typography variant='body' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Category
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 140,
            backgroundColor: colors.primary[0],
            zIndex: 1,
          },
          thStyles: {
            position: 'sticky',
            left: 140,
            zIndex: 1,
          },
        },
        filterFn: (row: any, columnId, selectedCategories) => {
          const value: string = row.getValue(columnId) || '';
          return selectedCategories.includes(value);
        },
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.accessor('description', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Typography variant='body' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Definition
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 280,
            backgroundColor: colors.primary[0],
            zIndex: 1,
          },
          thStyles: {
            position: 'sticky',
            left: 280,
            zIndex: 1,
          },
        },
        enableSorting: true,
      }),
      columnHelper.accessor('metric', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Typography variant='body' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Metric
          </Typography>
        ),

        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 420,
            backgroundColor: colors.primary[0],
            borderRight: `1px solid ${colors.primary[30]}`,
            paddingRight: '16px',
            zIndex: 1,
          },
          thStyles: {
            position: 'sticky',
            left: 420,
            borderRight: `1px solid ${colors.primary[30]}`,
            paddingRight: '16px',
            zIndex: 1,
          },
        },
        filterFn: (row: any, columnId, selectedCategories) => {
          const value: string = row.getValue(columnId) || '';
          return selectedCategories.includes(value);
        },
        enableColumnFilter: true,
        enableSorting: true,
      }),
    ];

    if (
      !companyKpisData ||
      !companyKpisData?.[companyKpiRequestFrequency as CoreKpiRequestFrequency]?.length ||
      !coreKpiCategories
    )
      return cols;

    const periodCols = companyKpisData[selectedFrequencyPreview]
      .map((item) => item.period)
      .flatMap((period, i, arr) =>
        columnHelper.accessor(period.toString(), {
          // eslint-disable-next-line react/display-name
          cell: (info) => {
            return (
              <KpiPeriodValueCell
                isActive={activeCell === `${period}-${info.row.original.id}`}
                info={info}
                onDataCellChange={onDataCellChange}
                period={period}
                setActiveCell={setActiveCell}
                companyKpiRequestFrequency={companyKpiRequestFrequency}
                selectedFreqiuencyPreview={selectedFrequencyPreview}
              />
            );
          },
          header: () => (
            <HeaderCellWrapper>
              <Typography variant='overline' color={colors.primary[70]}>
                {period}
              </Typography>
            </HeaderCellWrapper>
          ),
          meta: {
            width: i === arr.length - 1 ? '100%' : '140px',
            maxWidth: i === arr.length - 1 ? 'none' : '140px',
            minWidth: '140px',
            alignHeader: 'right',
            headerWrapperWidth: '124px',
          },
          enableSorting: true,
        })
      );
    return [...cols, ...periodCols];
  }, [
    activeCell,
    colors.primary,
    companyKpiRequestFrequency,
    companyKpisData,
    coreKpiCategories,
    onDataCellChange,
    selectedFrequencyPreview,
    setActiveCell,
  ]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const sortedUniqueCategories = useMemo(() => {
    const allValues = Array.from(
      table.getColumn('categoryName')?.getFacetedUniqueValues().keys() ?? []
    );
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    setSelectedCategories(unique);
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, data]);

  const onChangeCategoriesFilter = useCallback(
    (categories: SelectItem[]) => {
      setSelectedCategories(categories);
      table.getColumn('categoryName')?.setFilterValue(categories.map((c) => c.value));
    },
    [table]
  );

  const sortedUniqueMetrics = useMemo(() => {
    const allValues = Array.from(table.getColumn('metric')?.getFacetedUniqueValues().keys() ?? []);
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    setSelectedMetrics(unique);
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, data]);

  const onChangeMetricsFilter = useCallback(
    (metrics: SelectItem[]) => {
      setSelectedMetrics(metrics);
      table.getColumn('metric')?.setFilterValue(metrics.map((c) => c.value));
    },
    [table]
  );

  return (
    <Wrapper>
      <Typography variant='h4' color={colors.primary[90]}>
        KPI Matrix
      </Typography>
      <ControlsWrapper>
        <LeftControlGroup>
          <SearchInput
            placeholder='Search for a KPI'
            style={{ width: '320px' }}
            onClear={() => table.getColumn('name')?.setFilterValue('')}
            onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
            value={table.getColumn('name')?.getFilterValue() ?? ''}
          />
          <Multiselect
            style={{ width: '206px' }}
            options={sortedUniqueCategories}
            value={selectedCategories}
            onChange={(_, val) => {
              onChangeCategoriesFilter(val as SelectItem[]);
            }}
            disablePortal
            optionName='Category'
            optionsName='Categories'
            fieldPlaceholder='Filter by Category'
            labelTypographyStyle={{ maxWidth: '85px' }}
            sx={{
              '.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
                paddingRight: '50px',
              },
            }}
          />
          <Multiselect
            style={{ width: '206px' }}
            options={sortedUniqueMetrics}
            value={selectedMetrics}
            onChange={(_, val) => {
              onChangeMetricsFilter(val as SelectItem[]);
            }}
            disablePortal
            optionName='Metric'
            fieldPlaceholder='Filter by Metric'
            labelTypographyStyle={{ maxWidth: '85px' }}
            sx={{
              '.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
                paddingRight: '50px',
              },
            }}
          />
        </LeftControlGroup>
        <RightControlGroup>
          {Boolean(frequencyPreviewOptions.length) && (
            <FrequencySelector>
              <Typography variant='body' color={colors.primary[90]}>
                Display
              </Typography>
              <ChipsSelectorV2
                buttonStyle={{ height: '32px' }}
                options={frequencyPreviewOptions}
                selectedItem={
                  frequencyPreviewOptions.find(
                    (o) => o.id === selectedFrequencyPreview
                  ) as SelectItem
                }
                onSelect={(item) => setSelectedFrequencyPreview(item.id as CoreKpiRequestFrequency)}
              />
            </FrequencySelector>
          )}
          <ButtonGroup>
            <Button
              startIcon={<CalendarIcon />}
              variant='outlined'
              color='primary'
              style={{ height: '32px' }}
              onClick={() => setIsTimeframeModalOpen(true)}
            >
              Manage Timeframe
            </Button>
            <Button
              onClick={() => navigate(`/${ROUTES.UPLOAD_COMPANY_KPIS}/${companyId}/1`)}
              startIcon={<UploadIcon />}
              variant='outlined'
              color='primary'
              style={{ height: '32px' }}
            >
              Upload Data
            </Button>
          </ButtonGroup>
        </RightControlGroup>
      </ControlsWrapper>
      <Table
        table={table}
        tableStyle={{ width: 'auto', minWidth: 'auto' }}
        tdStyle={{ paddingLeft: '16px', paddingRight: '0' }}
        thStyle={{ paddingLeft: '16px', paddingRight: '0' }}
        disableRowHover
        disableLayoutAnimation
        tableId={KPI_MATRIX_TABLE_ID}
        height='calc(100vh - 374px)'
      />

      <KpisMatrixTimeframeModal
        onClose={() => setIsTimeframeModalOpen(false)}
        startYear={(companyKpisData as CompanyKpisData)?.[SURVEY_FREQUENCY.ANNUALLY]?.[0]?.period}
        endYear={
          companyKpisData?.[SURVEY_FREQUENCY.ANNUALLY]?.[
            companyKpisData?.[SURVEY_FREQUENCY.ANNUALLY]?.length - 1
          ]?.period || ''
        }
        isOpen={isTimeframeModalOpen}
      />
    </Wrapper>
  );
};
