import { useMemo } from 'react';

import { BasicLayout } from './components/BasicLayout';
import { RequestsTable } from './components/RequestsTable/RequestsTable';
import { REPORT_STATUS } from '../types';
import { usePortCoPortalReports } from '../hooks/usePortCoPortalReports';

export const ApprovedRequests = () => {
  const { data: reports } = usePortCoPortalReports();

  const approvedRequests = useMemo(() => {
    return reports?.filter((report) => report?.status === REPORT_STATUS.APPROVED) || [];
  }, [reports]);

  return (
    <BasicLayout title='Accepted Requests'>
      <RequestsTable reports={approvedRequests} />
    </BasicLayout>
  );
};
