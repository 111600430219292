import { Button, styled, useTheme } from '@mui/material';
import {
  ACTION_BAR_HEIGHT,
  SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH,
} from '../../../constants/layoutSizes';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ActionButtonsFooter } from '../../../components/ActionButtonsFooter/ActionButtonsFooter';
import { useCallback, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { activeReportState, activeSurveyState, responsesState } from '../../../state/UIState';
import {
  LoadingId,
  QUESTION_TYPE,
  QuestionOption,
  REPORT_STATUS,
  SurveyQuestion,
} from '../../../types';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { REPORTS, useUpdateReport } from '../../../queries/useReports';
import { useNavigate } from 'react-router';
import { flattenTree } from '../../../utils/treeUtilities';
import { useQueryClient } from 'react-query';
import { ROUTES } from '../../../constants/routes';

const ActionsWrapper = styled('div')`
  margin-left: ${SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH}px;
  position: relative;
  height: ${ACTION_BAR_HEIGHT}px;
`;

const EditButtonIcon = styled(EditIcon)`
  path {
    fill: ${({ theme }) => theme.colors.accent[50]};
  }
`;

export const FooterButtons = () => {
  const [activeReport] = useAtom(activeReportState);
  const [isLoading, setIsLoading] = useState(false);
  const { startLoading, stopLoading } = useLoadingBar();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const queryClient = useQueryClient();

  const [response] = useAtom(responsesState);
  const [activeSurvey] = useAtom(activeSurveyState);

  const updateReport = useUpdateReport();

  const onSubmit = useCallback(async () => {
    if (!activeReport) return;
    setIsLoading(true);
    startLoading(LoadingId.completeReport);

    await updateReport.mutateAsync(
      { id: activeReport.id, status: REPORT_STATUS.IN_REVIEW },
      {
        onSuccess: () => {
          queryClient.refetchQueries(`${REPORTS}-${activeReport?.company?.id}`);
          queryClient.refetchQueries(`${REPORTS}-${activeReport.id}`);
          navigate(`/${ROUTES.PENDING_REQUESTS}`);
        },
        onSettled: () => {
          setIsLoading(false);
          stopLoading(LoadingId.completeReport);
        },
      }
    );
    return;

    // await sendEvaluationInvites.mutateAsync(activeReport.id);
  }, [activeReport, startLoading, updateReport, queryClient, navigate, stopLoading]);

  const isSubmitDisabled = useMemo(() => {
    //check if all required questions and subquestions are answered
    if (!activeSurvey || !response) return true;
    const allSections = flattenTree(activeSurvey.sections);
    const allQuestions = allSections.reduce((acc: SurveyQuestion[], section) => {
      const subQuestions: SurveyQuestion[] = [];
      for (const question of section.questions) {
        if (
          question.type === QUESTION_TYPE.SINGLE &&
          question.includeConditions &&
          response[question.id]
        ) {
          const option = response[question.id] as QuestionOption;
          option?.subQuestions && subQuestions.push(...option.subQuestions);
        }
      }
      return [...acc, ...section.questions, ...subQuestions];
    }, []);
    const requiredQuestions = allQuestions.filter((question) => question.isRequired);
    const answeredQuestions = requiredQuestions.filter((question) => response[question.id]);
    return requiredQuestions.length !== answeredQuestions.length;
  }, [response, activeSurvey]);

  const onEditSubmission = async () => {
    startLoading(LoadingId.editSubmission);
    setIsLoading(true);
    await updateReport.mutateAsync(
      { id: activeReport?.id, status: REPORT_STATUS.IN_PROGRESS },
      {
        onError: (e) => {
          console.error(e);
        },
        onSettled: () => {
          stopLoading(LoadingId.editSubmission);
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <ActionsWrapper>
      <ActionButtonsFooter
        onSubmit={onSubmit}
        isSubmitDisabled={isLoading || isSubmitDisabled}
        submitText={'Submit Report'}
        bgColor={colors.primary[0]}
        secondaryButton={
          activeReport?.status === REPORT_STATUS.SUBMITTED ? (
            <Button
              variant='outlined'
              style={{ width: '173px' }}
              onClick={onEditSubmission}
              startIcon={<EditButtonIcon />}
            >
              Edit Submission
            </Button>
          ) : undefined
        }
      />
    </ActionsWrapper>
  );
};
