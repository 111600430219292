import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ColumnFiltersState,
  Row,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useRequestsColumns } from './useRequestsColumns';
import { styled } from '@mui/material';
import { flattenTree } from '../../../utils/treeUtilities';
import { getLastDateOfCurrentPeriod } from '../../../utils/formatters';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { QuestionRow, REPORT_STATUS, Report, SelectItem, User } from '../../../types';
import { Table } from '../../../components/Table/Table';
import { getPeriodByFrequencyAndReportDate } from '../../../utils/companyUtils';
import { useReportsByCompanyId } from '../../../queries/useReports';
import { RequestGroupsSkeletonLoader } from '../../../components/SkeletonLoader/RequestGroups.SkeletonLoader';
import { useAtomValue } from 'jotai';
import { userState } from '../../../state/UIState';

const Container = styled('div')``;

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;

export type TRequest = {
  id: number;
  surveyName: string;
  status: REPORT_STATUS;
  progress: number;
  reportingPeriod: string;
  deadline: string;
  exportData: QuestionRow[];
};

interface Props {
  reports: Report[];
  onRowClick?: (row: Row<TRequest>) => void;
}

export const RequestsTable = ({ reports, onRowClick }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<SelectItem[]>([]);
  const [data, setData] = useState<TRequest[]>(() => []);
  const user: User = useAtomValue(userState) as User;
  const { isLoading } = useReportsByCompanyId(user?.company?.id);

  useEffect(() => {
    const requestTableData = reports?.map((report) => {
      const sections = flattenTree(report.survey.sections);
      const questions = sections.map((section) => section.questions).flat();

      const deadlineDate =
        getLastDateOfCurrentPeriod(report.request.frequency, report.created_at) || '';

      return {
        id: report.id,
        surveyName: report.survey.name,
        status: report?.status,
        progress: report?.progress,
        reportingPeriod: getPeriodByFrequencyAndReportDate(
          report.request.frequency,
          report.month,
          report.year,
          true
        ),
        deadline: deadlineDate,
        exportData: questions.map((question) => ({
          id: question.id,
          question: question.name,
          answer: report.response ? report.response[question.id] : null,
          overrideAnswer: report.overrideResponse ? report.overrideResponse[question.id] : null,
          type: question.type,
        })),
      };
    });
    setData(requestTableData);
  }, [reports]);

  const columns = useRequestsColumns();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const sortedUniqueStatuses = useMemo(
    () =>
      Array.from(table.getColumn('status')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((value, id) => ({ id, value })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, data]
  );

  const onChangeStatusesFilter = useCallback(
    (statuses: SelectItem[]) => {
      setSelectedStatuses(statuses);
      table.getColumn('status')?.setFilterValue(statuses.map((s) => s.value));
    },
    [table]
  );

  useEffect(() => {
    if (!columnFilters?.length) {
      setSelectedStatuses(sortedUniqueStatuses);
    }
  }, [columnFilters?.length, sortedUniqueStatuses]);

  if (isLoading) {
    return <RequestGroupsSkeletonLoader />;
  }

  return (
    <Container>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a request'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('surveyName')?.setFilterValue('')}
          onChange={(e) => table.getColumn('surveyName')?.setFilterValue(e.target.value)}
          value={table.getColumn('surveyName')?.getFilterValue() ?? ''}
        />
        <Multiselect
          style={{ width: '220px' }}
          options={sortedUniqueStatuses}
          value={selectedStatuses}
          onChange={(_, val) => {
            onChangeStatusesFilter(val as SelectItem[]);
          }}
          disablePortal
          optionsName='Statuses'
          fieldPlaceholder='Filter by Statuses'
        />
      </FiltersWrapper>
      <Table table={table} height='auto' onRowClick={onRowClick} />
    </Container>
  );
};
