import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { Button, Tab, Tabs, styled, useTheme } from '@mui/material';
import { CompanyHeader } from './CompanyHeader';
import { ChangeEvent, useState } from 'react';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-blue.svg';
import { ROUTES } from '../../constants/routes';
import { useCompanyById } from '../../queries/useCompanies';
import { CompanyPageSkeletonLoader } from '../../components/SkeletonLoader/CompanyPage.SkeletonLoader';
import { doNothingFunc } from '../../utils/doNothingFunc';
import { Typography } from '../../components/Typography/Typography';
import { useInitKpiMatrix } from '../SIngleCompany/hooks/useInitKpiMatrix';

const Wrapper = styled('div')`
  overflow: hidden;
`;

const TabsWrapper = styled('div')`
  position: relative;
`;

const tabs = [
  { label: 'KPIs', path: ROUTES.COMPANY_PERFORMANCE },
  { label: 'Checklist', path: ROUTES.COMPANY_CHECKLIST },
];

export const Company = () => {
  const params = useParams();
  const { isLoading } = useCompanyById(Number(params.id));

  const { isLoading: isLoadingKpiMatrixData } = useInitKpiMatrix(Number(params.id));

  const { colors } = useTheme();
  const navigate = useNavigate();

  const { state } = useLocation();

  const [value, setValue] = useState(params.activeTab || ROUTES.COMPANY_PERFORMANCE);

  const handleChange = (_: ChangeEvent<object>, newValue: string) => {
    setValue(newValue);
  };

  if (isLoading || isLoadingKpiMatrixData) return <CompanyPageSkeletonLoader />;

  return (
    <AnimatePresence>
      <Wrapper>
        <CompanyHeader />

        <TabsWrapper>
          <Button
            variant='text'
            startIcon={<SettingsIcon style={{ marginTop: '-2px' }} />}
            style={{
              minWidth: 'auto',
              position: 'absolute',
              right: '32px',
              top: '5px',
              zIndex: 1,
            }}
            onClick={doNothingFunc}
          >
            <Typography variant='subtitle2' color={colors.accent[50]}>
              Manage View
            </Typography>
          </Button>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='simple tabs example'
            sx={{
              minHeight: '0',
              height: '42px',
              padding: '0 16px',
              borderBottom: `1px solid ${colors.primary[30]}`,
              '.MuiTabs-flexContainer': {
                height: '100%',
                gap: '16pxx',
                textTransform: 'none',
                paddingLeft: '24px',
                position: 'relative',
              },
            }}
          >
            {tabs.map((tab) => {
              return (
                <Tab
                  key={tab.path}
                  className={`mui-custom-tab`}
                  onClick={() => navigate(tab.path, { state })}
                  value={tab.path}
                  sx={{
                    '&&.mui-custom-tab.Mui-selected p': {
                      color: colors.accent[50],
                    },
                  }}
                  label={
                    <Typography
                      variant='subtitle2'
                      color={colors.primary[70]}
                      style={{ textTransform: 'none' }}
                    >
                      {tab.label}
                    </Typography>
                  }
                />
              );
            })}
          </Tabs>
          <Outlet />
        </TabsWrapper>
      </Wrapper>
    </AnimatePresence>
  );
};
