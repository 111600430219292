import { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import { HTMLMotionProps, motion } from 'framer-motion';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Tr = styled('tr')<
  HTMLMotionProps<'tr'> & { selectable?: string; disabled?: boolean; disablehover?: string }
>`
  height: 40px;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  &:not(:only-child):hover {
    ${({ theme, disablehover }) =>
      disablehover === 'true' ? '' : `background-color: ${theme.colors.gray[20]};`}
  }
  ${({ selectable }) => (selectable === 'true' ? 'cursor: pointer;' : '')}
  &.selected-row td {
    background-color: ${({ theme }) => theme.colors.accent[5]};
    border-top: 1px solid ${({ theme }) => theme.colors.accent[50]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.accent[50]};
  }
  &.selected-row td:first-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.accent[50]};
  }
  &.selected-row td:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.accent[50]};
  }
  &.no-data {
    background-color: ${({ theme }) => theme.colors.primary[5]};
    td {
      border-top: 1px solid ${({ theme }) => theme.colors.primary[20]};
    }
  }
`;

interface IProps {
  children: ReactNode;
  selectable?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  disableAnimation?: boolean;
  disableHover?: boolean;
  disableLayoutAnimation?: boolean;
  id?: string;
}

export const TableRow: FC<IProps> = ({
  children,
  selectable = false,
  disabled,
  onClick,
  className,
  style,
  disableAnimation = false,
  disableHover = false,
  disableLayoutAnimation = false,
  id,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: id || '',
  });

  const dndStyles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Tr
      as={motion.tr}
      initial={{ opacity: disableAnimation ? 1 : 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.3 }}
      exit={{ opacity: disableAnimation ? 1 : 0 }}
      layout={disableLayoutAnimation ? false : true}
      selectable={selectable.toString()}
      disabled={disabled}
      onClick={onClick}
      className={className}
      style={{ ...style, ...dndStyles }}
      disablehover={disableHover.toString()}
      id={id}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {children}
    </Tr>
  );
};
