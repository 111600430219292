import { styled } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';

import { useState } from 'react';
import { useAtom } from 'jotai';

import { Outlet } from 'react-router';
import { PortalSidebarMenu } from './PortalSidebarMenu/PortalSidebarMenu';
import {
  MAIN_SIDEBAR_MENU_EXPANDED_WIDTH,
  MAIN_SIDEBAR_MENU_WIDTH,
} from '../../constants/layoutSizes';
import { sidebarMenuPinnedState } from '../../state/sidebarMenuPinnedState';
import { configToastMessageState, isOpenToastMessageState } from '../../state/UIState';
import { ToastMessage } from '../../components/ToastMessage/ToastMessage';
import { LoadingBar } from '../../components/LoadingBar/LoadingBar';

const Wrapper = styled('div')`
  display: flex;
  height: 100vh;
  position: relative;
`;

const Content = styled(motion.div)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary[5]};
  overflow: auto;
  padding-left: ${MAIN_SIDEBAR_MENU_WIDTH}px;
`;

const LoadingBarWrapper = styled('div')`
  width: calc(100% - ${MAIN_SIDEBAR_MENU_WIDTH}px);
  position: absolute;
  top: 0;
`;

export const PortCoPortalLayout = () => {
  const [isSideMenuExpanded, setIsSideMenuExpanded] = useState(false);
  const [isSideMenuPinned, setIsSideMenuPinned] = useAtom(sidebarMenuPinnedState);
  const [config] = useAtom(configToastMessageState);
  const [isOpen, setIsOpen] = useAtom(isOpenToastMessageState);

  const controls = useAnimation();

  const onPinToggle = () => {
    if (isSideMenuPinned) {
      setIsSideMenuPinned(false);
      controls.start({
        paddingLeft: ` ${MAIN_SIDEBAR_MENU_WIDTH}px`,
        transition: { duration: 0.3 },
      });
      return;
    }

    setIsSideMenuPinned(true);

    controls.start({
      paddingLeft: ` ${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px`,
      transition: { duration: 0.3 },
    });
  };

  return (
    <Wrapper>
      <PortalSidebarMenu
        setIsExpanded={setIsSideMenuExpanded}
        isExpanded={isSideMenuExpanded}
        onPinToggle={onPinToggle}
        isPinned={isSideMenuPinned}
      />
      <Content animate={controls}>
        {config && (
          <ToastMessage {...config} isOpen={isOpen} handleClose={() => setIsOpen(false)} />
        )}
        <LoadingBarWrapper>
          <LoadingBar />
        </LoadingBarWrapper>
        <Outlet />
      </Content>
    </Wrapper>
  );
};
