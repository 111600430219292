import { styled } from '@mui/material';
import { PORTAL_HEADER_HEIGHT } from '../../../../constants/layoutSizes';
import { QuestionnaireBreadcrumbs } from './QuestionnaireBreadcrumbs';

const Wrapper = styled('div')`
  height: ${PORTAL_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[30]};
  position: sticky;
  top: 0;
`;

export const QuestionnaireHeader = () => {
  return (
    <Wrapper>
      <QuestionnaireBreadcrumbs />
    </Wrapper>
  );
};
