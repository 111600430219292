import { useAtom } from 'jotai';
import { Breadcrumbs } from '../../../../components/Breadcrumbs/Breadcrumbs';
import { survayNameState } from '../../../../state/UIState';
import { ROUTES } from '../../../../constants/routes';

export const QuestionnaireBreadcrumbs = () => {
  const [surveyName] = useAtom(survayNameState);

  return (
    <Breadcrumbs
      currentRouteName={surveyName}
      prevRoutes={[
        {
          prevRoute: `/${ROUTES.PENDING_REQUESTS}`,
          prevRouteName: 'Reports',
        },
      ]}
    />
  );
};
