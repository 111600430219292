import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import { LoadingId, SURVEY_TYPE, Survey } from '../types';
import { REQUEST_GROUPS } from './useRequestGroups';

export const SURVEYS = 'surveys';
export const SYSTEM = 'system';

async function fetchSystemSurveys(): Promise<Survey[]> {
  const { data } = await Http.axios.get<Survey[]>(`/survey/system`);
  data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  return data;
}

export function useSystemSurveys() {
  return useQuery(`${SURVEYS}/${SYSTEM}`, () => fetchSystemSurveys(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function fetchSurveys(): Promise<Survey[]> {
  const { data } = await Http.axios.get<Survey[]>(`/survey-portal`);
  //sort by type and then by created_at
  data.sort((a, b) => {
    if (a.type === b.type && a.type === SURVEY_TYPE.CORE) {
      return a.name.localeCompare(b.name);
    }
    if (a.type === b.type) {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    }
    return a.type === SURVEY_TYPE.USER ? 1 : -1;
  });
  return data;
}

export function useSurveys() {
  return useQuery(SURVEYS, () => fetchSurveys(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function createSurvey(survey: Partial<Survey>): Promise<Survey> {
  const { data } = await Http.axios.post<Partial<Survey>, Survey>(`/survey`, survey);
  return data;
}

export function useCreateSurvey() {
  const { startLoading, stopLoading } = useLoadingBar();
  const queryClient = useQueryClient();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createSurvey, {
    onMutate: async (newSurvey) => {
      startLoading(LoadingId.createSurvey);
      await queryClient.cancelQueries(SURVEYS);
      const previousSurveys = queryClient.getQueryData(SURVEYS);
      queryClient.setQueryData(SURVEYS, (oldSurveys: Survey[] | undefined) =>
        oldSurveys ? [newSurvey as Survey, ...oldSurveys] : [newSurvey as Survey]
      );

      return { previousSurveys };
    },
    onError: (error, _, context) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create survey' });
      queryClient.setQueryData(SURVEYS, context?.previousSurveys);
    },
    onSettled: () => {
      queryClient.invalidateQueries(SURVEYS);
      stopLoading(LoadingId.createSurvey);
    },
  });
}

async function updateSurvey(survey: Partial<Survey>): Promise<Survey> {
  const { id, ...payload } = survey;
  const { data } = await Http.axios.patch<Partial<Survey>, Survey>(`/survey/${id}`, payload);
  return data;
}

export function useUpdateSurvey() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateSurvey, {
    onMutate: async () => {
      startLoading(LoadingId.updateSurvey);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update survey' });
    },
    onSettled: () => {
      queryClient.refetchQueries(SURVEYS);
      stopLoading(LoadingId.updateSurvey);
    },
  });
}

async function updateCoreKpisSurvey(survey: Partial<Survey>): Promise<Survey> {
  const { data } = await Http.axios.patch<Partial<Survey>, Survey>(
    `/survey/core-kpis-survey`,
    survey
  );
  return data;
}

export function useUpdateCoreKpisSurvey() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateCoreKpisSurvey, {
    onMutate: async () => {
      startLoading(LoadingId.updateSurvey);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update survey' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(SURVEYS);
      stopLoading(LoadingId.updateSurvey);
    },
  });
}

async function updateCheckistQarterlySurvey(survey: Partial<Survey>): Promise<Survey> {
  const { data } = await Http.axios.patch<Partial<Survey>, Survey>(
    `/survey/check-list-quarterly`,
    survey
  );
  return data;
}

export function useUpdateChecklistQuarterlySurvey() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateCheckistQarterlySurvey, {
    onMutate: async () => {
      startLoading(LoadingId.updateSurvey);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update quarterly survey' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(SURVEYS);
      stopLoading(LoadingId.updateSurvey);
    },
  });
}

async function updateChecklistAnnuallySurvey(survey: Partial<Survey>): Promise<Survey> {
  const { data } = await Http.axios.patch<Partial<Survey>, Survey>(
    `/survey/check-list-annually`,
    survey
  );
  return data;
}

export function useUpdateChecklistAnnuallySurvey() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateChecklistAnnuallySurvey, {
    onMutate: async () => {
      startLoading(LoadingId.updateSurvey);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update annual survey' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(SURVEYS);
      stopLoading(LoadingId.updateSurvey);
    },
  });
}

export async function fetchSurveyById(id: number): Promise<Survey | null> {
  if (!id) return null;
  const { data } = await Http.axios.get<Survey>(`/survey/${id}`);
  return data;
}

async function deleteSurvey(id: number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/survey/${id}`);
  return data;
}

export function useDeleteSurvey() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(deleteSurvey, {
    onMutate: async (surveyId) => {
      startLoading(LoadingId.deleteSurvey);
      await queryClient.cancelQueries(SURVEYS);
      const previousSurveys = queryClient.getQueryData(SURVEYS);
      queryClient.setQueryData(SURVEYS, (oldSurveys: Survey[] | undefined) => {
        return (
          oldSurveys?.filter((survey) => {
            return survey.id !== surveyId;
          }) ?? []
        );
      });
      return { previousSurveys };
    },
    onError: (error, _, context) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to delete survey' });
      queryClient.setQueryData(SURVEYS, context?.previousSurveys);
    },
    onSettled: () => {
      queryClient.invalidateQueries(SURVEYS);
      queryClient.removeQueries(REQUEST_GROUPS);
      stopLoading(LoadingId.deleteSurvey);
    },
  });
}

async function archiveSurvey(id: number): Promise<Survey> {
  const payload = { isArchived: true };
  const { data } = await Http.axios.patch<Partial<Survey>, Survey>(`/survey/${id}`, payload);
  return data;
}

export function useArchiveSurvey() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(archiveSurvey, {
    onMutate: async (surveyId) => {
      startLoading(LoadingId.archiveSurvey);
      await queryClient.cancelQueries(SURVEYS);
      const previousSurveys = queryClient.getQueryData(SURVEYS);
      queryClient.setQueryData(SURVEYS, (oldSurveys: Survey[] | undefined) => {
        return (
          oldSurveys?.filter((survey) => {
            return survey.id !== surveyId;
          }) ?? []
        );
      });
      return { previousSurveys };
    },
    onError: (error, _, context) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to archive survey' });
      queryClient.setQueryData(SURVEYS, context?.previousSurveys);
    },
    onSettled: () => {
      queryClient.invalidateQueries(SURVEYS);
      stopLoading(LoadingId.archiveSurvey);
    },
  });
}

async function updateExclusionsScreeningSurvey(survey: Partial<Survey>): Promise<Survey> {
  const { data } = await Http.axios.patch<Partial<Survey>, Survey>(
    `/survey/exclusions-screening`,
    survey
  );
  return data;
}

export function useUpdateExclusionsScreeningSurvey() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateExclusionsScreeningSurvey, {
    onMutate: async () => {
      startLoading(LoadingId.updateSurvey);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update exclusions screening survey' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(SURVEYS);
      stopLoading(LoadingId.updateSurvey);
    },
  });
}
