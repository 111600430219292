import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import { MenuItemLabel } from './components/MenuItemLabel';
import { MenuItemBase } from './components/MenuItemBase';
import { TMenuItem } from '../../../types';
import { useActiveRoute } from './useActiveRoute';

interface Props {
  item: TMenuItem;
  isExpanded: boolean;
  rootPath?: string;
}

export const MenuItem = ({ item, isExpanded, rootPath = '' }: Props) => {
  const navigate = useNavigate();

  const onClick = (route?: string) => {
    if (!route) return;
    navigate(`/${route}`);
  };

  const activeRoute = useActiveRoute(rootPath);

  const isActive = useMemo(() => {
    return activeRoute === item.id;
  }, [activeRoute, item.id]);

  return (
    <MenuItemBase
      onClick={() => onClick(item.route)}
      icon={isActive ? item.iconActive : item.icon}
      isExpanded={isExpanded}
      labelContent={<MenuItemLabel isActive={isActive} title={item.title} />}
    />
  );
};