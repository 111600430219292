import { Button, styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { SelectItem } from '../../types';

type Props = {
  options: SelectItem[];
  selectedItem: SelectItem | null;
  onSelect: (item: SelectItem) => void;
  chipHeight?: string;
  buttonStyle?: React.CSSProperties;
};

const Wrapper = styled('div')`
  display: flex;
`;

const ButtonChip = styled('div')<{ isSelected?: boolean }>`
  .MuiButtonBase-root.MuiButton-outlined {
    border: 1px solid
      ${({ theme, isSelected }) =>
        isSelected ? theme.colors.accent[50] : theme.colors.primary[30]};
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.accent[50] : theme.colors.primary[90]};
  }
  .MuiButtonBase-root.MuiButton-outlined:hover {
    border: 1px solid ${({ theme }) => theme.colors.accent[50]};
    color: ${({ theme }) => theme.colors.accent[50]};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.accent[5] : 'transparent'};
  }
`;

export const ChipsSelectorV2: FC<Props> = ({
  options,
  selectedItem,
  onSelect,
  chipHeight,
  buttonStyle,
}) => {
  const { accent } = useTheme().colors;
  return (
    <Wrapper>
      {options.map((o, i) => (
        <ButtonChip key={o.id} isSelected={o.id === selectedItem?.id}>
          <Button
            startIcon={o.icon ? o.icon : null}
            variant='outlined'
            onClick={() => onSelect(o)}
            style={{
              backgroundColor: o.id === selectedItem?.id ? accent[5] : 'transparent',
              height: chipHeight ? chipHeight : 'auto',
              borderRadius: i === 0 ? '4px 0 0 4px' : i === options.length - 1 ? '0 4px 4px 0' : 0,
              ...buttonStyle,
            }}
          >
            {o.value}
          </Button>
        </ButtonChip>
      ))}
    </Wrapper>
  );
};
