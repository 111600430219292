import { Dispatch, SetStateAction } from 'react';
import { CORE_KPI_FORMAT } from '../../../../types';
import { EditableTextCell } from '../../../../components/EditableTextCell/EditableTextCell';
import { EditableNumericCell } from '../../../../components/EditableNumericCell/EditableNumericCell';

interface Props {
  value: string;
  onValueChange: (value: string) => void;
  format: CORE_KPI_FORMAT;
  setActiveCell: Dispatch<SetStateAction<string | null>>;
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
}

export const CoreKpiInput = ({
  value,
  format,
  onValueChange,
  setActiveCell,
  inputValue,
  setInputValue,
}: Props) => {
  if (format === CORE_KPI_FORMAT.TEXT) {
    return (
      <EditableTextCell
        value={value}
        onValueChanged={onValueChange}
        onBlur={() => setActiveCell(null)}
        autofocus
        style={{ height: '30px', width: '124px' }}
        inputValue={inputValue}
        setInputValue={setInputValue}
        maxLength={32}
      />
    );
  }

  return (
    <EditableNumericCell
      value={value}
      onValueChanged={onValueChange}
      inputPadding='0 4px'
      format={format}
      onBlur={() => setActiveCell(null)}
      autofocus
      style={{ height: '30px', width: '124px' }}
      inputValue={inputValue}
      setInputValue={setInputValue}
    />
  );
};
