import { styled } from '@mui/material';
// import { Performance } from './Performance';
import { KpisMatrix } from './KpisMatrix/KpisMatrix';
import { useCoreKpiCategories } from '../../../queries/useCoreKpiCategories';

const Wrapper = styled('div')``;

export const PerformanceTab = () => {
  useCoreKpiCategories();
  return (
    <Wrapper>
      {/* <Performance /> */}
      <KpisMatrix />
    </Wrapper>
  );
};
