import { styled } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/icons/starcier.svg';
import { ReactComponent as Compass } from '../../assets/icons/esg-compass.svg';

const LogoWrapper = styled('div')`
  gap: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const EsgCompassLogo = () => {
  return (
    <LogoWrapper>
      <Logo style={{ transform: 'scale(0.9)' }} />
      <Compass />
    </LogoWrapper>
  );
};
