import { Dispatch, SetStateAction } from 'react';
import { SidebarMenu } from '../../../components/SidebarMenu/SidebarMenu';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from '../../../types';
import { useAtomValue } from 'jotai';
import { userState } from '../../../state/UIState';
import { useMenuItems } from '../../../hooks/useMenuItems';

interface Props {
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
  onPinToggle: () => void;
  isPinned: boolean;
}

export const PortalSidebarMenu = ({ setIsExpanded, isExpanded, onPinToggle, isPinned }: Props) => {
  const user: User = useAtomValue(userState) as User;
  const menuItems = useMenuItems(user?.company?.id);

  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <SidebarMenu
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      onPinToggle={onPinToggle}
      isPinned={isPinned}
      menuItems={menuItems}
      username={user?.email || ''}
      activeRole={''}
      onUserAvatarClick={handleLogout}
      rootPath={'/'}
    />
  );
};
